<template>
    <div v-if="show">
        <vxe-modal v-model="show" :title="name" width="800" min-width="800" height="80%" min-height="80%" esc-closable
                   resize>
            <vxe-toolbar perfect size="mini">
                <template slot="buttons">
                    <div class="cf" style="padding: 0 10px">
                        <div class="fl">
                            <el-button type="primary" size="mini" @click="addCar()" style="margin-right: 10px">
                                关联车辆
                            </el-button>
                            <el-input v-model="search.code" clearable placeholder="输入车牌号" size="mini"
                                      style="width: 120px; margin-right: 10px" @change="searchInit()">
                            </el-input>
                        </div>
                    </div>
                </template>
            </vxe-toolbar>
            <vxe-table stripe :align="allAlign" :data="data" height="74%" border :export-config="{}" :print-config="{}"
                       highlight-hover-row auto-resize size="mini" ref="vehicle" resizable row-id="id" v-loading="loading"
                       element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow
                       show-overflow @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
                <template slot="empty">
                    <el-empty></el-empty>
                </template>
                    <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
                    <vxe-table-column field="code" min-width="120" title="车牌号"></vxe-table-column>
            </vxe-table>
            <vxe-pager perfect size="small" align="left" v-loading="loading" :current-page="page.currentPage"
                       :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
                       :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                       :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
                <template v-slot:left>
                    <div class="fl footer-check">
                        <p>
                            已选
                            <b style="font-size: 10px">{{ checkLength }}</b> 条
                        </p>
                    </div>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="getDelete()">删除</el-button>
                </template>
            </vxe-pager>
        </vxe-modal>
        <car-detail ref="carDetail" @init="searchInit"/>
    </div>
</template>

<script>
import carDetail from './carDetail.vue'

export default {
    data() {
        return {
            show: false,
            name: '',
            row: {},
            data: [],
            allAlign: 'center',
            loading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                totalResult: 0
            },
            search: {},
            isNull:['', null, undefined],
            checkBox: [],
            checkLength: 0
        }
    },
    components: {
        carDetail
    },
    methods: {
        display(data) {
            this.row = data.row
            this.name = data.name
            this.show = true
            this.checkBox = []
            this.checkLength = 0
            this.page = {
                pageSize: 20,
                currentPage: 1,
                totalResult: 0
            }
            this.search = {}
            this.init()
        },
        /** 货位列表查询 */
        init() {
            this.loading = true
            this.data = []
            const params = {
                params: {
                    page: this.page.currentPage,
                    pageCount: this.page.pageSize,
                    orderId: this.row.id,
                    type: 'Sell'
                }
            }
            if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
            this.$axios.get('/order/ordercar/page', params).then(res => {
                if (res) {
                    const data = res.data.data
                    data.list.forEach(item => {
                        this.data.push({code: item})
                    })
                    this.page.totalResult = data.total
                    this.loading = false
                } else {
                    this.loading = false
                }
            })
                .catch(() => {
                    this.loading = false
                })
        },
        addCar() {
            const data = {
                data: {
                    orderId: this.row.id,
                    type: 'Sell',
                    carCodes: []
                },
                name: '关联车辆',
            }
            this.$refs.carDetail.display(data)
        },
        /** 条数更改 */
        handlePageChange({
                             currentPage,
                             pageSize
                         }) {
            this.page.currentPage = currentPage
            this.page.pageSize = pageSize
            this.init()
        },
        searchInit() {
            this.page.currentPage = 1
            this.init()
        },
        getDelete() {
            let isOk = false
            if (this.checkLength > 0) {
                isOk = true
            } else {
                isOk = false
                this.$message.warning('请选择至少一条数据')
            }
            if (isOk) {
                const params = {
                    carCodes: this.checkBox.map((item) => item.code),
                    orederId: this.row.id,
                    type: 'Sell'
                }
                this.$confirm(`确定删除这些数据吗？`, '提示', {
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                    type:'warning',
                })
                    .then(() => {
                        this.$axios.post('/order/ordercar/delete', params).then((res) => {
                            if (res) {
                                this.init()
                                this.$message.success('删除成功')
                            }
                        })
                    })
                    .catch(() => {
                    })
            }
        },
        changeBoxChange() {
            this.checkBox = this.$refs.vehicle.getCheckboxRecords()
            this.checkLength = this.$refs.vehicle.getCheckboxRecords().length
        },
    }
}
</script>
