<template>
    <div v-if="show">
        <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="80%" min-height="80%" showFooter
                   esc-closable mask-closable resize>
            <template v-slot>
                <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
                    <el-form-item prop="companyId" label="公司">
                        <el-select filterable default-first-option v-model="data.companyId" placeholder="选择公司"
                                   style="width: 100%">
                            <el-option :label="item.name" :value="item.id" v-for="(item, key) in company" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="supplierId" label="发货单位">
                        <el-select filterable default-first-option v-model="data.supplierId" placeholder="选择发货单位"
                                   style="width: 100%">
                            <el-option :label="item.name" :value="item.id" v-for="(item, key) in company" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="customerId" label="收货单位">
                        <el-select filterable default-first-option v-model="data.customerId" placeholder="选择收货单位"
                                   style="width: 100%">
                            <el-option :label="item.name" :value="item.id" v-for="(item, key) in customer" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="productId" label="产品">
                        <el-select filterable default-first-option v-model="data.productId" placeholder="选择产品"
                                   style="width: 100%">
                            <el-option :label="item.name" :value="item.id" v-for="(item, key) in product" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="stockId" label="仓库">
                        <el-select filterable default-first-option v-model="data.stockId" placeholder="选择仓库"
                                   style="width: 100%"
                                   @change="changeSpaceName">
                            <el-option :label="item.spaceName" :value="item.id" v-for="(item, key) in spaceName"
                                       :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="locationId" label="货位" ref="location">
                        <el-select filterable default-first-option v-model="data.locationId" placeholder="选择货位"
                                   style="width: 100%"
                                   @focus="focusLocationId">
                            <el-option :label="item.name" :value="item.id" v-for="(item, key) in location" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="orderNum" label="订单数量">
                        <el-input v-model="data.orderNum" placeholder="输入订单数量" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="settleMethod" label="结算方式" ref="location">
                        <el-select filterable default-first-option v-model="data.settleMethod" placeholder="选择结算方式"
                                   style="width: 100%">
                            <el-option :label="item.label" :value="item.value"
                                       v-for="(item, key) in [{value: 'Primary', label: '原发结算'},{value: 'Receipt', label: '实收结算'}]" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="roadMethod" label="路耗方式" ref="location">
                        <el-select filterable default-first-option v-model="data.roadMethod" placeholder="选择路耗方式"
                                   style="width: 100%">
                            <el-option :label="item.label" :value="item.value" v-for="(item, key) in [{value: 'Num', label: '路耗量'},{value: 'Rate', label: '路耗率'}]" :key="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="roadRate" label="路耗率(%)" v-if="data.roadMethod === 'Rate'">
                        <el-input v-model="data.roadRate" placeholder="输入路耗率(%)" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="roadNum" label="路耗量(吨)" v-if="data.roadMethod === 'Num'">
                        <el-input v-model="data.roadNum" placeholder="输入路耗量(吨)" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="goodPrice" label="货物单价">
                        <el-input v-model="data.goodPrice" placeholder="输入货物单价" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="feightPrice" label="运费单价">
                        <el-input v-model="data.feightPrice" placeholder="输入运费单价" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="note" label="备注">
                        <el-input v-model="data.note" placeholder="输入备注" type="textarea"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <template v-slot:footer>
                <div style="text-align: center">
                    <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
                </div>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            name: '',
            data: {},
            rules: {
                companyId: [{required: true, trigger: 'change', message: '选择公司'}],
                supplierId: [{required: true, trigger: 'change', message: '选择发货单位'}],
                customerId: [{required: true, trigger: 'change', message: '选择收货单位'}],
                productId: [{required: true, trigger: 'change', message: '选择产品'}],
                stockId: [{required: true, trigger: 'change', message: '选择仓库'}],
                // locationId: [{ required: true, trigger: 'change', message: '选择货位' }],
                orderNum: [{required: true, trigger: 'blur', message: '输入订单数量'}],
            },
            loading: false,
            customer: [],
            supplier: [],
            spaceName: [],
            location: [],
            company: [],
            product: [],
        }
    },
    methods: {
        /** 打开界面方法 */
        display(data) {
            this.show = true
            this.data = this.$utils.clone(data.data, true)
            this.name = data.name
            this.customer = data.customer
            this.supplier = data.supplier
            this.company = data.company
            this.spaceName = data.spaceName
            this.product = data.product
            this.$nextTick(() => {
                if (this.$refs.form) this.$refs.form.clearValidate()
            })
        },
        changeSpaceName() {
            this.$set(this.data, 'locationId', null)
            this.$nextTick(() => {
                this.$refs.location.clearValidate()
            })
        },
        focusLocationId() {
            this.location = []
            if (['', null, undefined].includes(this.data.stockId)) this.$message.warning('请先选择仓库！')
            else {
                const params = {
                    params: {
                        spaceNameId: this.data.stockId,
                        pageNum: 1,
                        pageSize: 10000,
                    },
                }
                this.$axios.get('/order/Location/queryList', params).then((res) => {
                    if (res) this.location = res.data.data.list
                })
            }
        },
        /** 保存方法 */
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    const params = this.data
                    this.$axios
                        .post('/order/sellorder', params)
                        .then((res) => {
                            if (res) {
                                this.$message.success('新增成功')
                                this.show = false
                                this.$emit('init')
                            }
                            this.loading = false
                        })
                        .catch((err) => {
                            console.log('新增失败', err)
                            this.loading = false
                        })
                } else {
                    return false
                }
            })
        },
    },
}
</script>